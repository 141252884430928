import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import admin_login from '../views/admin/login.vue'
import admin from '../views/admin/admin.vue'
import dashboard from '../views/admin/dashboard.vue'
import commodities from '../views/admin/commodities.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/admin/login',
        name: 'admin_login',
        component: admin_login
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: dashboard
            },
            {
                path: 'commodities',
                name: 'commodities',
                component: commodities
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
