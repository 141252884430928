<template>
    <div class="home">
        <div class="head">
            <div class="title">
                Commodities Tracker
            </div>
            <div class="f_web">
                <div class="">
                    <filter_vue @filter_by="filter_by($event)"
                    :categories="categories" :currencies="currencies"/>
                </div>
                <div class="search">
                    <div class="s_icon">
                        <search_vue :size="'20px'" :style="{'height': '22px'}"/>
                    </div>
                    <input type="text" placeholder="Search commodity" @input="search()" v-model="search_text">
                </div>
            </div>
            <div class="mobile_options">
                <filter_vue @filter_by="filter_by($event)"
                :categories="categories" :currencies="currencies"/>

                <div class="m_search" @click="show_mobile_search = true">
                    <search_vue :size="'20px'" :style="{'height': '22px'}"/>
                </div>
            </div>

        </div>

        <div class="main">
            <div class="table">
                <div class="table_head">
                    <div class="table_head_item" @click="toggle_star()" :class="[star ? 'starred' : '']">
                        <starred_vue :size="'20px'" :style="{'height': '20px'}" v-if="star"/>
                        <star_vue :size="'20px'" :style="{'height': '20px'}" v-else/>
                    </div>
                    <div class="table_head_item">#</div>
                    <div class="table_head_item">
                        Commodity
                    </div>
                    <div class="table_head_item" @click="sort_by('price')">
                        Price
                        <sort_vue :f="current_sort" :u="'price'"/>
                    </div>
                    <div class="table_head_item" @click="sort_by('day_value')">
                        Day
                        <sort_vue :f="current_sort" :u="'day_value'"/>
                    </div>
                    <div class="table_head_item web" @click="sort_by('percent')">
                        %
                        <sort_vue :f="current_sort" :u="'percent'"/>
                    </div>
                    <div class="table_head_item web" @click="sort_by('weekly')">
                        Weekly
                        <sort_vue :f="current_sort" :u="'weekly'"/>
                    </div>
                    <div class="table_head_item web" @click="sort_by('monthly')">
                        Monthly
                        <sort_vue :f="current_sort" :u="'monthly'"/>
                    </div>
                    <div class="table_head_item web" @click="sort_by('yoy')">
                        YOY
                        <sort_vue :f="current_sort" :u="'yoy'"/>
                    </div>
                    <div class="table_head_item web">
                        Category
                    </div>
                </div>

                <loader_vue v-if="loading" class="loading"/>
                <div v-else>
                    <commodity_vue v-for="commodity in commodities" :key="commodity.no" :commodity="commodity"/>
                </div>
            </div>

            <div v-if="!loading">

                <commodities_table :commodities_data="commodities_data"/>
            </div>

        </div>

        <div class="mobile_search" v-if="show_mobile_search">
            <div class="m_icon" @click="show_mobile_search = false; search_text = ''; search()">
                <close_vue :size="'20px'" :style="{'height': '22px'}"/>
            </div>
            <input type="text" placeholder="Search Commodity" @input="search()" v-model="search_text">
        </div>

        <div class="subcribe">
            <div class="subscribe_content">
                Subcribe to our weekly newsletter for updates and insight
                <a href="https://weeklycommoditiesreport.substack.com" target="blank">
                    <div class="subscribe_button">Subscribe</div>
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
let db = new Localbase('commodity')

import star_vue from '@/icons/star.vue'
import starred_vue from '@/icons/starred.vue';

import search_vue from "@/icons/search.vue";
import filter_vue from '@/components/filter.vue'
import close_vue from '@/icons/close.vue'

import loader_vue from '@/components/loader.vue';
import commodity_vue from '@/components/commodity/commodity.vue'


import sort_vue from '@/components/sort.vue'

import commodities_table from '@/components/commodity_2/table.vue'

import { store } from '@/store/store';

import { inject, ref } from "vue";
import { useToast } from 'vue-toastification';

const star = ref(false)

const commodities_data = ref([])

const all_commodities = ref([])
const commodities = ref([])
const categories = ref([])
const currencies = ref([])

const axios = inject('axios')
const axios_2 = inject('axios_2')
const toast = useToast()

const loading = ref(true)
const init = () => {
    axios.get('/commodities')
    .then(data => {
        let r = data.data

        commodities_data.value = r.commodities_data

        all_commodities.value = JSON.parse(JSON.stringify(r.response))
        commodities.value = r.response
        categories.value = r.categories
        currencies.value = r.currencies

        loading.value = false
    })
    
    axios_2.post('/page_view')
    .then(data => {
        let r = data.data
        if (r.success) {
            let date = localStorage.getItem('date')
            if (date != r.date) {
                axios_2.post('/unique_view')
            } 
            localStorage.setItem('date', r.date)
        }
    })
}
init()


const toggle_star = () => {
    if (star.value) {
        commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
        star.value = false
    } else {
        db.collection('watchlist').get().then(docs => {
            if (docs.length == 0 ) {
                toast.info("There are no commoditiies on your watch list")
            } else {

                let watchlist = []
                for (let x of docs) {
                    for (let y of all_commodities.value) {
                        if (x.symbol == y.symbol) {
                            watchlist.push(y)
                        }
                    }
                }

                commodities.value = JSON.parse(JSON.stringify(watchlist))

                star.value = true
            }
        })
    }

    store.star = star.value
}


const filter_by = (e) => {
    star.value = false

    let filtered = []


    if (e.category.length == 0 && e.currency.length == 0) {
        commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
        return
    }

    for (let category of e.category) {
        for (let y of all_commodities.value) {
            if (category == y.category) {
                filtered.push(y)
            }
        }
    }

    if (filtered.length == 0) {
        for (let currency of e.currency) {
            for (let y of all_commodities.value) {
                if (currency == y.currency) {

                    let found = false

                    for(let f of filtered) {
                        if (f.symbol == y.symbol) found = true
                    }

                    if (!found) filtered.push(y)
                }
            }
        }
    } else {
        let new_filter = []

        if (e.currency.length > 0) {

            for (let y of filtered) {
                for (let currency of e.currency) {
                    if (currency == y.currency) {
                        new_filter.push(y)
                    }
                }
            }

            filtered = new_filter
        }
    }
    
    filtered.sort((a, b) => a['no'] - b['no'] )
    
    commodities.value = JSON.parse(JSON.stringify(filtered))
}

const show_mobile_search = ref(false)

const search_text = ref('')
const search = () => {
    store.search_text = search_text.value
    
    let new_c = []

    star.value = false

    if (search_text.value == '') {
        commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
        return
    }

    for(let x of all_commodities.value) {
        if (x.commodity.toLowerCase().includes(search_text.value.toLowerCase())) {
            new_c.push(x)
        }
    }

    commodities.value = JSON.parse(JSON.stringify(new_c))
}

const current_sort = ref({
    'by': '',
    'order': ''
})

const sort_by = (what) => {

    if (current_sort.value.by != what) {
        current_sort.value = {
            'by': '',
            'order': ''
        }
    }

    current_sort.value.by = what

    if (current_sort.value.order == '') {
        console.log('f');
        commodities.value.sort((a, b) => parseFloat(b[what]) - parseFloat(a[what]) )
        current_sort.value.order = 'desc'

        return
    }
    
    if (current_sort.value.order == 'desc') {
        console.log('s');
        commodities.value.sort((a, b) =>  parseFloat(a[what]) - parseFloat(b[what]) )
        current_sort.value.order = 'asc'

        return
    }

    if (current_sort.value.order == 'asc') {
        console.log('t');
        commodities.value = JSON.parse(JSON.stringify(all_commodities.value))
        current_sort.value.order = ''

        return
    }
}




</script>

<style scoped>
    .home {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    .head {
        padding: 20px;
        background-color: var(--bg-color);
        color: white;
        font-weight: 500;
        font-size: 20px;
        position: sticky;
        top: 0px;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    .f_web {
        display: flex;
        /* align-items: center; */
        justify-content: space-between;
        gap: 20px;
    }
    .search {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        padding-left: 10px;
    }
    .search input {
        outline: none;
        padding: 10px;
        border: none;
    }

    .mobile_options {
        display: none;
    }
    .m_search {
        fill: white;
        padding: 5px;
        cursor: pointer;
    }




    .main {
        /* max-width: 1200px; */
        padding: 0px 20px;
        box-sizing: border-box;
        /* margin: 0 auto; */
        overflow: auto;
        /* margin-bottom: 100px; */
        
        flex: auto;
    }


    .table {
        position: relative;
        border-radius: 15px;
        /* overflow: hidden; */
        /* border: 1px solid #e0e0e0; */
        min-width: 760px;
        /* position: relative; */

    }
    .table_head {
        display: grid;
        grid-template-columns: 5% 5% 20% 10% 10% 10% 10% 10% 10% 10%;
        /* background-color: var(--transparent-color); */
        /* color: var(--bg-color); */
        font-weight: 500;
        font-size: 12px;
        padding: 20px;
        border-bottom: 1px solid #333;

        background-color: white;

        position: sticky;
        top: 0px;
        z-index: 2;
    }
    .table_head_item {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
    }
    
    .starred {
        fill: rgb(221, 148, 0);
    }

    
    .loading {
        margin-top: 20px;
    }


    .mobile {
        display: none;
    }

    .mobile_search {
        position: fixed;
        top: 0px;
        padding: 0px;
        z-index: 10;

        display: none;
        gap: 10px;
        grid-template-columns: max-content auto;
        align-items: center;

        margin: 10px;
        padding: 7px;
        padding-left: 15px;

        background-color: white;
        border-radius: 5px;
        width: calc(100% - 20px);
        box-sizing: border-box;
    }
    .mobile_search input {
        
        outline: none;
        padding: 10px;
        border: none;
    }


    .subcribe {
        /* position: fixed; */
        /* bottom: 0px; */
        /* left: 0px; */
        width: 100%;
        padding: 15px 20px;
        box-sizing: border-box;

        background-color: #f8f8f8;

        border-top: 2px solid var(--bg-color);
        /* color: white; */
    }
    .subscribe_content {
        max-width: 1200px;
        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-size: 14px;
    }
    .subscribe_button {
        background-color: var(--bg-color);
        color: white;
        padding: 10px 30px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 5px;
    }


    @media screen and (max-width: 800px) {
        .web {
            display: none;
        }

        .main {
            padding: 0px;
        }

        .table {
            min-width: unset;
        }
        
        .table_head {
            display: grid;
            grid-template-columns: 8% 7% 35% 20% 30% ;
        }

        .mobile {
            display: block;
        }
    }

    
    @media screen and (max-width: 700px) {
        .f_web {
            display: none;
        }
        .mobile_options {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        .mobile_search {
            display: grid;
        }
    }

    
    @media screen and (max-width: 650px) {


        
        .table {
        }


    }
</style>